import React, { useState } from 'react';

import Logo from '../../assets/images/belgianmobilitycompany.svg';
import {
  LogoContainer,
  Container,
  InnerContainer,
} from './styledComponent';

const Nav = () => {
  return (
    <Container>
      <InnerContainer>
        <LogoContainer href="/">
          <img src={Logo} height={20} />
        </LogoContainer>
      </InnerContainer>
    </Container>
  );
};

export default Nav;
