import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';

import GlobalStyle from '../../theme/global';
import FontStyle from '../../theme/fonts';

import Nav from '../../components/Nav';

export default function Page({ children }) {
  return (
    <>
      <ParallaxProvider>
        <GlobalStyle />
        <FontStyle />
        <Nav />
        {children}
      </ParallaxProvider>
    </>

  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};
